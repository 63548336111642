<template>
    <!-- <div class="user"> -->
        <div class="user_box" v-if="isShow">
            <img class="close" src="@/assets/close@2x.png" @click="close" alt="" />
            <div class="user_box_left">
                <!-- 信息 -->
                <div class="user_box_left_info">
                    <div class="user_box_left_info_top">
                        <div class="user_box_left_info_top_tx">
                            <div class="user_box_left_info_top_tx_img">
                                <img :src="user.avatar" alt="" />
                            </div>
                            <div class="tx_name">
                                <span>{{ user.nickname }}</span>
                                <img style="width: 18px;height: 18px" src="@/assets/iconPark-vip3x@2x.png" alt="" v-if="user?.is_period_of_validity == 1" />
                                <img style="width: 18px;height: 18px" src="@/assets/huiv.png" alt="" v-if="user?.is_period_of_validity == 0" />
                            </div>
                        </div>
<!--                        <div class="user_box_left_info_top_k">-->
                        <div style="width: 100%;">
                            <div class="user_box_right_info_time" style="position: relative;width: 220px;">
                                <img style="width: 18px;height: 18px" src="@/assets/iconPark-vip3x@2x.png" alt="" v-if="user?.is_period_of_validity == 1" />
                                <img style="width: 18px;height: 18px" src="@/assets/huiv.png" alt="" v-if="user?.is_period_of_validity == 0" />
                                <div class="user_box_right_info_item_right">
                                    <span>到期时间：</span>
                                    <span>{{ user?.period_of_validity || '暂无' }}<span v-if="user?.is_period_of_validity == 0">(已到期)</span></span>
                                    <div style="position: absolute;right: 10px;"><el-button type="warning" @click="bulletbox">续费</el-button></div>
                                </div>
                            </div>
                            <div class="user_box_right_info_time_code" style="position: relative;">
                                <div class="user_box_right_info_item_right" style="display: flex;align-items: center">
                                    <span style="margin: 0 0 5px 0;">邀请码：{{ user?.invitation_code || '暂无' }}</span>
<!--                                    @click="shareinvite(user?.invitation_code_key || '暂无')"-->
<!--                                    <div style="position: absolute;right: 10px;">-->
<!--&lt;!&ndash;                                        <el-button type="warning">分享</el-button>&ndash;&gt;-->
<!--                                        <el-popover-->
<!--                                            :width="150"-->
<!--                                            trigger="hover">-->
<!--                                            <template #reference>-->
<!--                                                <div class="fenxiang-box">-->
<!--                                                    <div class="warning">-->
<!--                                                        分享-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </template>-->
<!--                                            <div style="width: 100%; display: flex; align-items: center;">-->
<!--                                                <div @click="shareWeChat(Invitation)"-->
<!--                                                     style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;">-->
<!--                                                    <img style="width: 30px; height: 30px;" src="@/assets/wx.png" alt=""/>-->
<!--                                                    <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">微信</span>-->
<!--                                                </div>-->
<!--                                                <div @click="shareQQ(Invitation)"-->
<!--                                                     style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;">-->
<!--                                                    <img style="width: 30px; height: 30px;" src="@/assets/QQ@2x.png" alt=""/>-->
<!--                                                    <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">QQ</span>-->
<!--                                                </div>-->
<!--                                                <div @click="shareMicroBlog(Invitation)"-->
<!--                                                     style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;">-->
<!--                                                    <img style="width: 30px; height: 30px;" src="@/assets/wb.png" alt=""/>-->
<!--                                                    <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">微博</span>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </el-popover>-->
<!--                                    </div>-->
                                </div>
                                <div style="color: #fff;display: flex;align-items: center;justify-content: center;font-size: 10px;">欢迎邀请朋友一起复盘</div>
                            </div>
                        </div>

<!--                        </div>-->

                    </div>
                    <div class="user_box_left_info_rem">
                        <span>关于账号权限及获取方式的说明关于账号权限及获取方式的说明关于账号权限及获取方式的说明</span>
                    </div>
                    <div class="user_box_left_info_b">
                        <div class="user_box_left_info_edit" @click="goedituser">修改个人信息</div>
                        <div class="user_box_left_info_edit" @click="loginout">退出</div>
                    </div>
                </div>
                <!-- 统计 -->
<!--                <div class="user_box_left_num">-->
<!--                    <div class="user_box_left_num_item">-->
<!--                        <span class="a">{{ user?.count_list?.post_count || 0 }}</span>-->
<!--                        <span class="b">发帖数</span>-->
<!--                        <div class="user_box_left_num_item_x"></div>-->
<!--                    </div>-->
<!--                    <div class="user_box_left_num_item">-->
<!--                        <span class="a">{{ user?.count_list?.total_views || 0 }}</span>-->
<!--                        <span class="b">总浏览量</span>-->
<!--                        <div class="user_box_left_num_item_x"></div>-->
<!--                    </div>-->
<!--                    <div class="user_box_left_num_item">-->
<!--                        <span class="a">{{ user?.count_list?.comment_count || 0 }}</span>-->
<!--                        <span class="b">评论数</span>-->
<!--                        <div class="user_box_left_num_item_x"></div>-->
<!--                    </div>-->
<!--                    <div class="user_box_left_num_item">-->
<!--                        <span class="a">{{ user?.count_list?.favorite_count || 0 }}</span>-->
<!--                        <span class="b">收藏数</span>-->
<!--                        <div class="user_box_left_num_item_x"></div>-->
<!--                    </div>-->
<!--                    <div class="user_box_left_num_item">-->
<!--                        <span class="a">{{ user?.count_list?.my_favorites_count || 0 }}</span>-->
<!--                        <span class="b">我的收藏</span>-->
<!--                    </div>-->
<!--                </div>-->
                <!-- 分类 -->
                <div class="user_box_left_art">
                    <div class="user_box_left_art_list">
                        <div :class="item.active ? 'user_box_left_art_list_item active': 'user_box_left_art_list_item'" v-for="(item, index) in menu" :key="index" @click.stop="clickMenu(index, item.id)">
                            <span style="font-size: 17px;">{{ item.name }}</span>
                            <div class="user_box_left_art_list_item_active" v-if="item.active"></div>
                        </div>
                    </div>
                    <div class="user_box_left_art_box" :infinite-scroll-immediate="false" v-infinite-scroll="loadArticle">
                        <div class="user_box_left_art_box_item" v-for="(item, index) in from.list" :key="index">
                            <div class="art_title" style="position: relative">
                                <text>{{ item.title }}</text>
<!--                                <div class="updatePost" v-if="mytype == 2">修改</div>-->
<!--                                <div class="deletePost" v-if="mytype == 2">删除</div>-->
                                <el-popover :width="150" trigger="click">
                                    <template #reference>
                                        <div class="chatIcon_list_item_footer_left_item" style="cursor: pointer;position: absolute;right: 5%">
                                            <img style="width: 25px;" src="@/assets/dian.png" alt="" v-if="mytype === 2"/>
                                        </div>
                                    </template>
                                    <div style="width: 100%; display: flex; align-items: center;justify-content: space-between;">
                                        <div class="updatePost" style="color:#fff;z-index: 999;" v-if="mytype === 2" @click="updatePost(item)">修改</div>
                                        <div class="deletePost" style="color:#fff;" v-if="mytype === 2" @click="deletePost(item)">删除</div>
                                    </div>
                                </el-popover>
                            </div>
                            <div class="art_info" style="width: 88%;" v-html="item.content" v-if="type !== 'video'" @click="jumpDetails(item)"></div>
                            <div class="art_info" style="width: 88%;" v-else @click="jumpDetails(item)">{{ item.description }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="user_box_right">
                <!-- 积分 -->
                <div class="user_box_right_integral">
                    <div class="user_box_right_integral_head">
                        <img src="@/assets/12@2x.png" alt="" />
                        <span class="c">现有积分：</span>
                        <span class="d">{{ user.score }}</span>
                    </div>
                    <div class="user_box_right_integral_list" :infinite-scroll-immediate="false" :infinite-scroll-distance="1" v-infinite-scroll="getScore">
                        <div class="user_box_right_integral_list_item" v-for="(item, index) in from.scoreList" :key="index">
                            <div class="integral_content">{{item.change_time.substring(0, 10)}}&nbsp;{{ returnText(item.change_type) }}</div>
                            <div class="integral_num">{{item.seq_flag == 1 ? '+' : '-'}}{{ item.change_num }}</div>
                        </div>
                    </div>
                    <div class="user_box_right_integral_footer" @click="points_rule">
                        <div class="user_box_right_integral_footer_btn">点击查看积分规则</div>
                    </div>
                </div>
                <!-- 联系方式/地址 -->
                <div class="user_box_right_integral" style="height: 288px;margin-top: 9px;">
                    <div class="user_box_right_integral_head">-->
                        <img src="@/assets/12@2x.png" alt="" />
                        <span class="c">订单列表</span>
                    </div>
                    <div class="user_box_right_integral_list" style="height: 205px;" :infinite-scroll-immediate="false" :infinite-scroll-distance="1" v-infinite-scroll="getScore">
                        <div class="user_box_right_integral_list_item" v-for="(item, index) in order_info.list" :key="index">
                            <div class="user_box_right_info_item_right" style="background: rgba(32, 86, 137, 0.5);padding: 10px;border-radius: 10px;">
                                <div style="display: flex;justify-content: space-between;align-items: center">
                                    <span style="font-size: 12px;">{{ item.goods_name }}</span>
                                    <span style="font-size: 12px;margin-top: 0;color: #09c056;" v-if="item.pay_status == 1">{{ item.pay_status_name }}</span>
                                    <span style="font-size: 12px;margin-top: 0;color: #ff3c37;" v-else>{{ item.pay_status_name }}</span>
                                </div>
                                   <div style="display: flex;justify-content: space-between;align-items: center;color: #FFFFFF">
                                       <text style="font-size: 11px;">有效期: {{ item.period_validity }}</text>
                                       <text style="display: flex;justify-content: space-between;align-items: center;font-size: 11px;">金额: {{ item.price }}</text>
                                   </div>
                            </div>
                        </div>
                    </div>
                </div>

<!--                <div class="user_box_right_info" style="overflow: auto;box-sizing: border-box;">-->
<!--                    <div class="user_box_right_integral_head">-->
<!--                        <img src="@/assets/12@2x.png" alt="" />-->
<!--                        <span class="c">订单列表</span>-->
<!--                    </div>-->
<!--                    <div class="user_box_right_info_item" v-for="(item, index) in order_info.list" :key="index">-->
<!--                        <div class="user_box_right_info_item_right">-->
<!--                            <span>{{ item.goods_name }}</span>-->
<!--                            <div style="display: flex;justify-content: space-between;align-items: center;color: #FFFFFF">-->
<!--                                <text>{{ item.pay_time }}</text>-->
<!--                                <text style="display: flex;justify-content: space-between;align-items: center">{{ item.pay_time }}</text>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </div>
    <!-- </div> -->


    <div style="position: fixed;top: 50%;left:50%;transform: translate(-50%, -50%);;z-index: 990;width: 688px;background-color: #fff;border-radius: 10px;padding: 5px" v-if="is_update">
        <div class="item_pl_user" style="display: flex;justify-content: space-between;">
            <span> 修改内容 </span>
            <span style="cursor: pointer" @click.stop="closeupdate()">X</span>
        </div>
        <textareaComponent :con="update_post" :inputtype="8" @result="sendMessageResult" v-if="is_update"/>
    </div>

    <el-dialog v-model="dialogFormVisible2" title="开通会员" width="500">
        <div style="margin-top: 15px;margin-left: 20px;">
        <el-button @click="payment">付费</el-button>
        <el-button @click="exchange">积分兑换</el-button>
        <el-button @click="apply_for">审核申请</el-button>
        </div>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false">取消</el-button>
      </span>
        </template>
    </el-dialog>
    <el-dialog v-model="dialogFormVisible" title="开通会员">
        <div style="width: 100%;margin-bottom: 30px;">
            <span><span v-html="info"></span></span>
            <span>服务协议：<a :href="agreement">服务协议</a></span>
        </div>
        <div style="height: 230px;margin-right: 20px;overflow-y: auto" v-if="paytype==1">
            <div style="width: 20%;height: 200px;border: 2px #0B3D6C solid;background-color: #d3dce6;margin: 10px 0 0 10px;display: inline-block;float: left" v-for="(item, index) in vipinfo" :key="index">
                <div style="height: 50%;font-size: 22px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;">{{ item.name }}</div>
<!--                <div style="font-size: 18px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;"><text style="color: red;font-size: 22px">{{ item.membership_price }}</text>￥</div>-->
                <div style="font-size: 18px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;" v-if="pay_status === 1"><text style="color: red;font-size: 22px">{{ item.membership_price }}</text>￥</div>
                <div style="font-size: 18px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;" v-else-if="pay_status === 3"><text style="color: red;font-size: 22px">{{ item.membership_price }}</text>￥</div>
                <div style="font-size: 18px;font-weight: bold;text-align: center;padding-top: 10px;color: #000;" v-else><text style="color: red;font-size: 22px">{{ item.membership_score }}</text></div>
                <div style="text-align: center;padding-top: 15px">
<!--                    <el-button type="primary" @click="dredge(item.id)">开通</el-button>-->
                    <el-button type="primary" v-if="pay_status === 1" @click="dredge(item.id)">开通</el-button>
                    <el-button type="primary" v-else-if="pay_status === 3" @click="dredge2(item.id)">审核申请</el-button>
                    <el-button type="primary" v-else @click="dredge1(item.id)">积分开通</el-button>
                </div>
            </div>
        </div>
        <div style="height: 200px;text-align: center;display: flex" v-else>
            <img :src="payimg" style="width: 200px;height: 200px" alt="" />
        </div>

        <template #footer>
          <span class="dialog-footer" style="margin-right: 10px;">
              <text style="color: #000000;padding-right: 20px;" v-if="pay_status !== 1">现有积分:{{ user.score }}</text>
            <el-button @click="cancellation()">取消</el-button>
          </span>
        </template>
    </el-dialog>
    <el-dialog v-model="dialogFormVisible1" title="查看积分规则">
        <div style="width: 100%;margin-bottom: 30px;">
            <span><span v-html="score_rule"></span></span>
        </div>
        <template #footer>
          <span class="dialog-footer" style="margin-right: 10px;">
            <el-button @click="dialogFormVisible1 = false">取消</el-button>
          </span>
        </template>
    </el-dialog>
</template>

<script setup>
import textareaComponent from '@/components/textareaComponent.vue'
import {ref, reactive, defineExpose, onMounted, computed, defineEmits,onBeforeUnmount} from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import {
    logout,
    getScoreList,
    getMyPlazaPostList,
    getMyFavorite,
    getMyCommentList,
    getVipList,
    createVip,
    payVip,
    Vipquery,
    systemConfig, goodsShow, scorePay, getUserDetails, delPost, orderList, getMyCommentedArticles, getUserConunt
} from '@/api/index'
import {ElMessage} from 'element-plus'
import {toBase64} from "@/utils/utils";
const emit = defineEmits(['updateContent'])
const dialogFormVisible = ref(false)
const dialogFormVisible1 = ref(false)
const dialogFormVisible2 = ref(false)
const update_post = ref([])
const order_info = ref([])
const is_update = ref(false)
const pay_status = ref(1)
const pay_al_type = ref(1)
const pay_alid = ref(1)
const order_exchange = ref(1)
const store = useStore()
const router = useRouter()
let isShow = ref(false)
let type = ref('t')
let type1 = ref('')
let payimg = ref('')
let Invitation = ref('')
let paytype = ref(1)
let mytype = ref(1)
let getUserConunt_list = ref([])
let vipinfo = ref([])
let score_rule = ref('')
let info = ref('')
let agreement = ref('')
let menu = ref([
    {
        name: '我的帖子',
        id: 1,
        active: true
    },
    {
        name: '点赞/收藏',
        id: 2,
        active: false
    },
    {
        name: '评论列表',
        id: 3,
        active: false
    },
])
let from = reactive({
    page: 0,
    end: false,
    list: [],

    scorePage: 1,
    scoreEnd: false,
    scoreList: []
})
const user = computed(() => store.state.userInfo)

const jumpDetails = (item) => {
    if(item.comment_type === "video"){
        const routeUrl = router.resolve({
            path: "/home/videoDetails",
            query: {id: item.id, type: item.category_type, comment_type: item.comment_type,status: '1'}
        });
        window.open(routeUrl.href, "_blank");
    }else{
        const routeUrl = router.resolve({
            path: "/home/forumDetails",
            query: {id: item.id, type: item.category_type, comment_type: item.comment_type,status: '1'}
        });
        window.open(routeUrl.href, "_blank");
    }

}

// 切换分类
const clickMenu = (i, id) => {
    from.list = []
    from.end = false
    from.page = 1
    menu.value.forEach((item, index) => {
        item.active = i === index;
    })
    switch(id) {
        case 1:
            type.value = 't'
            getnewsMy()
            mytype.value = 2
            break;
        case 2:
            type.value = 'video'
            type1.value = 'plate_post'
            getfavorite(true)
            mytype.value = 1
            break;
        case 3:
            type.value = 'list'
            getpost(true)
            mytype.value = 3
            break;
        // case 4:
        //     type.value = 'plaza_post'
        //     getfavorite(true)
        //     mytype.value = 1
        //     break;
        // case 6:
        //     type.value = 'news'
        //     getCommentList()
        //     break;
    }
}

const deletePost = (item) => {
    delPost({
        category_type: item.category_type,
        id: item.id
    }).then(res => {
        if (res.code === 1) {
            ElMessage({message: res.msg, type: 'success'})
            menu.value.forEach((item, index) => {
                item.active = index === 0;
            })
            store.dispatch('getUserInfo')   // 更新个人信息
            getScore()  // 获取积分列表
            getnewsMy()   // 获取我发布的文章
            open()
            clickMenu(0,1)
            document.querySelector('body').classList.add('no-scroll');
        }
    })
}

const updatePost = (item) => {

    // console.log(item)
    // return

    let files = []
    for (let i = 0; i < item.file_data.length; i++) {
        files.push(item.file_data[i].url)
    }
    item.file_data = files
    update_post.value = item
    is_update.value = true
}

const closeupdate = (item) => {
    is_update.value = false
}

const sendMessageResult = (event) => {
    if (event) {
        is_update.value = false
        clickMenu(0,1)
    }
}

// 类型判断
const returnText = (type) => {
    switch(type) {
        case '1':
            return '注册'
        case '2':
            return '发帖'
        case '3':
            return '评论'
        case '4':
            return '转发'
        case '5':
            return '收藏'
        case '6':
            return '加精'
        case '7':
            return '置顶'
        case '8':
            return '被收藏'
        case '9':
            return '被转发'
        case '10':
            return '下载附件'
        case '14':
            return '拉新'
    }
}

const shareQQ = (key) => {
    var url = window.location.href;
    const newUrl = url.replace('home', '');
    let urls = ''
    if (key === "暂无"){
        urls = newUrl;
    }else{
        urls = newUrl+'?key='+key;
    }
    var encodedUrl = encodeURIComponent(urls);
    var shareUrl = 'http://connect.qq.com/widget/shareqq/index.html?url=' + encodedUrl;
    window.open(shareUrl);
}

// 分享到微博
const shareMicroBlog = (key) => {

    let message = String(location.href)
    const newUrl = message.replace('home', '');
    let urls = ''
    if (key === "暂无"){
        urls = newUrl;
    }else{
        urls = newUrl+'?key='+key;
    }
    var url = 'https://service.weibo.com/share/share.php?url=' + encodeURIComponent(urls) + '&appkey=MrShi&title=测试标题&language=zh_cn'
    window.open(url)
    // count=表示是否显示当前页面被分享数量(1显示)(可选，允许为空)
    // &url=将页面地址转成短域名，并显示在内容文字后面。(可选，允许为空)
    // &appkey=用于发布微博的来源显示，为空则分享的内容来源会显示来自互联网。(可选，允许为空)
    // &title=分享时所示的文字内容，为空则自动抓取分享页面的title值(可选，允许为空)
    // &pic=自定义图片地址，作为微博配图(可选，允许为空)
    // &ralateUid=转发时会@相关的微博账号(可选，允许为空)
    // &language=语言设置(zh_cn|zh_tw)(可选)
}

//分享到微信
const shareWeChat = (key) => {
    var url = window.location.href;
    const newUrl = url.replace('home', '');
    let urls = ''
    if (key === "暂无"){
        urls = newUrl;
    }else{
        urls = newUrl+'?key='+key;
    }
    var encodedUrl = encodeURIComponent(urls);
    var qrcodeUrl = 'https://login.weixin.qq.com?url=' + encodedUrl;
    window.open(qrcodeUrl)
};

// 获取收藏列表
const getfavorite = (reset) => {
    if (reset) {
        from.page = 1
        from.list = []
        from.end = false
    }
    if (from.end) return
    getMyFavorite({
        table_name: type.value,
        page: from.page,
        limit: 5
    }).then(res => {
        if (res.code === 1) {
            from.list.push(...res.data.video_list)
            if (res.data.total === from.list.length) {
                from.end = true
            }
        }
    })
    getMyFavorite({
        table_name: type1.value,
        page: from.page,
        limit: 5
    }).then(res => {
        if (res.code === 1) {
            from.list.push(...res.data.plate_post_list)
            if (res.data.total === from.list.length) {
                from.end = true
            }
        }
    })
}

const getpost = (reset) => {
    if (reset) {
        from.page = 1
        from.list = []
        from.end = false
    }
    if (from.end) return
    getMyCommentedArticles({
        page: from.page,
        limit: 10
    }).then(res => {
        if (res.code === 1) {
            from.list.push(...res.data.list)
            if (res.data.total === from.list.length) {
                from.end = true
            }
        }
    })
}


const shareinvite = (key) => {
    const fullUrl = window.location.href;
    const newUrl = fullUrl.replace('home', '');
    let url = ''
    if (key === "暂无"){
        url = newUrl;
    }else{
        url = newUrl+'?key='+key;
    }
    const tempInput = document.createElement('input');
    tempInput.value = url;
    document.body.appendChild(tempInput);
    tempInput.select();
    tempInput.setSelectionRange(0, 99999);
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    ElMessage({message: '已保存链接', type: 'success'})
}

const payment = (val) => {
    pay_status.value = 1
    dialogFormVisible2.value = false
    dialogFormVisible.value = true
}

const exchange = (val) => {
    pay_status.value = 2
    dialogFormVisible2.value = false
    dialogFormVisible.value = true
}

const dredge1 = (id) => {
    createVip({
        goods_type:'user_group',
        table_name:'user_group',
        goods_id:id,
        pay_type:2,
    }).then(res => {
        if (res.code === 1){
            order_exchange.value = res.data.order_id
            dialogFormVisible.value = false
            scorePay({order_id:order_exchange.value}).then(res1=>{
                if (res1.code === 1){
                    ElMessage({message: res1.msg, type: 'success'})
                }
            })
        }
    })
}

const apply_for = () => {
    pay_status.value = 3
    dialogFormVisible2.value = false
    dialogFormVisible.value = true
}
const dredge2 = (id) => {
    createVip({
        goods_type:'user_group',
        table_name:'user_group',
        goods_id:id,
        pay_type:3,
    }).then(res => {
        if (res.code === 1){
            order_exchange.value = res.data.order_id
            dialogFormVisible.value = false
            ElMessage({message: '申请成功等待审核', type: 'success'})
        }
    })
}
const bulletbox = () => {
    dialogFormVisible2.value = true
    getVipList().then(res => {
        if (res.code === 1) {
            vipinfo.value = res.data
        }
    })
    systemConfig().then(res => {
        if (res.code === 1){
            info.value = res.data.web_service_agreement_text
            agreement.value = res.data.web_service_agreement
        }
    })
}
const points_rule = () => {
    dialogFormVisible1.value = true
    systemConfig().then(res => {
        if (res.code === 1){
            score_rule.value = res.data.web_score_rule
        }
    })
}

const cancellation = () => {
    dialogFormVisible.value = false
    paytype.value = 1
}
const dredge = (id) => {
    createVip({
        goods_type:'user_group',
        table_name:'user_group',
        goods_id:id,
    }).then(res => {
        if (res.code === 1){
            payVip({order_id:res.data.order_id}).then(res1=>{
                if (res1.code === 1){
                    payimg.value = res1.data.code_url
                    paytype.value = 2
                    const intervalId = setInterval(() => {
                        Vipquery({ order_id: res.data.order_id }).then(res2 => {
                            if (res2.code === 1) {
                                if (res2.data.pay_status === 1) {
                                    clearInterval(intervalId); // 停止轮询
                                    location.reload();
                                }
                            }
                        });
                    }, 2000);
                }
            })
        }

    })
}


// 获取我的评论列表
const getCommentList = () => {
    getMyCommentList({
        page: from.page,
        limit: 10,
        type: 'news'
    })
}

// 退出
const loginout = () => {
    logout()
        .then(res => {
            store.commit('empty')
            router.push('/')
            document.querySelector('body').classList.remove('no-scroll');
        })
}

const goedituser = () => {
    const routeUrl = router.push({
        path: "/perfect",
        query: { type: 2}
    });
}


// 获取积分
const getScore = () => {
    if (from.scoreEnd) return
    getScoreList({
        limit: 10,
        page: from.scorePage
    }).then(res => {
        if (res.code === 1) {
            if (res.data.score_list.length > 0) {
                from.scorePage += 1
                from.scoreList = [...from.scoreList, ...res.data.score_list]
            } else {
                from.scoreEnd = true
            }
        }
    })
}

const open = () => {
    menu.value.forEach((item, index) => {
        item.active = index === 0;
    })
    store.dispatch('getUserInfo')   // 更新个人信息
    getScore()  // 获取积分列表
    getnewsMy()   // 获取我发布的文章
    getUserConunts()
    // document.querySelector('body').classList.add('no-scroll');
    isShow.value = true

    orderList({
        pay_status:'all',
        limit:50,
    }).then(res => {
        order_info.value = res.data
    });
}

const close = () => {
    isShow.value = false
    from.list = []
    from.scoreList = []
    from.end = false
    from.scoreEnd = false
    from.page = 1
    from.scorePage = 1
    document.querySelector('body').classList.remove('no-scroll');
}

// 触底加载
const loadArticle = () => {
    from.page += 1
    if (type.value == 't') {
        getnewsMy()
    } else if( type.value == 'video' ) {
        getfavorite()
    } else {
        getpost()
    }
}

// 获取我发布的文章
const getnewsMy = async () => {
    mytype.value = 2
    if (!from.end) {
        let res = await getMyPlazaPostList({ limit: 10, page: from.page })
        if (res.code === 1) {
            // from.page = from.page += 1
            from.list = [...from.list, ...res.data.list]
            if (res.data.list === 0) {
                from.end = true
            }
        }
    }
}

// 向父组件暴露方法
defineExpose({
    open, close
})

const obtain_user = () => {
    getUserDetails().then(res => {
        if(res.code === 1){
            Invitation.value = res.data.invitation_code_key
        }
    });
}

// const handleScroll = () => {
//     const userBox = document.querySelector('.user_box');
//     const scrollY = window.scrollY || window.pageYOffset;
//     console.log(userBox,userBox,`${1 + scrollY}px`,888)
//     if (userBox !== null){
//         userBox.style.top = `${1 + scrollY}px`;
//     }
// };
//
// onBeforeUnmount(() => {
//     // 在组件销毁前，移除滚动事件监听
//     window.removeEventListener('scroll', handleScroll);
// });

const getUserConunts = () => {
    menu.value[0].name = `我的帖子`;
    menu.value[1].name = `点赞/收藏`;
    menu.value[2].name = `评论列表`;
    getUserConunt().then(res=>{
        getUserConunt_list.value = res.data
        const keys = Object.keys(getUserConunt_list.value);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value = getUserConunt_list.value[key];
            menu.value[i].name += `（${value}）`;
        }
    })
}


onMounted(() => {
    obtain_user()
    // window.addEventListener('scroll', handleScroll);
})
</script>

<style lang="scss" scoped>

.video-right-option {
  display: flex;
  position: absolute;
  top: 15px;
  right: 10px;
}

.deletePost {
  height: 20px;
  line-height: 20px;
  text-align: center;
  background: linear-gradient(0deg, #085EAF, #2791F4);
  border-radius: 4px;
  font-size: 9px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  cursor: pointer;
  width: 45px;
  margin-left: 10px;
}
.updatePost {
  height: 20px;
  line-height: 20px;
  text-align: center;
  background: linear-gradient(0deg, #085EAF, #2791F4);
  border-radius: 4px;
  font-size: 9px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  cursor: pointer;
  width: 45px;
}

.fenxiang-box{
    background: #e6a23c;
    border-radius: 3px;
    text-align: center;
    font-size: 11px;
    padding: 0 9px;
    color: #FFFFFF;
    width: 35px;
    height: 30px;
    line-height: 30px;
}

.user_box_right_info_time {
    width: 100%;
    padding: 12px;
    height: 55px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background: linear-gradient(0deg, #085EAF, #2791F4);
    border-radius: 6px;
    margin-bottom: 9px;
  img {
        flex-shrink: 0;
        width: 13px;
        height: 15px;
        margin-right: 13px;
    }
}
.user_box_right_info_time_code{
    width: 100%;
    padding: 12px;
    height: 55px;
    box-sizing: border-box;
    background: linear-gradient(0deg, #085EAF, #2791F4);
    border-radius: 6px;
    margin-bottom: 9px;
}
.user_box_right_info_item_right {
    flex: 1;
    display: flex;
    flex-direction: column;
    span {
        font-size: 10px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        &:last-child {
            margin-top: 7px;
        }
    }
}
.dialog-footer button:first-child {
    margin-right: 10px;
}

// .user {
//     width: 100vw;
//     height: 100vh;
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: 9;
//     display: flex;
//     align-items: center;
//     justify-content: center;
    .user_box {

        position: absolute;
        padding: 22px;
        top: 1%;
        right: 40%;
        transform: translate(-30%);

        box-sizing: border-box;
        border-radius: 16px;
        background-color: rgba(6, 23, 50, 1);
        z-index: 9;
        display: flex;
        align-items: flex-start;
        .user_box_left {
            width: 435px;
            margin-right: 9px;
            .user_box_left_info {
                width: 100%;
                padding: 20px;
                height: 288px;
                background: #0D2E64;
                border-radius: 6px;
                box-sizing: border-box;
                .user_box_left_info_top {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .user_box_left_info_top_tx {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        .user_box_left_info_top_tx_img {
                            width: 84px;
                            height: 84px;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            img {
                                width: 84px;
                                height: 84px;
                            }
                        }
                        .tx_name {
                            display: flex;
                            align-items: center;
                            margin-top: 19px;
                            span {
                                font-size: 13px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                color: #FFFFFF;
                            }
                            img {
                                width: 13px;
                                height: 13px;
                                margin-left: 8px;
                            }
                        }
                    }
                    .user_box_left_info_top_k {
                        img {
                            width: 233px;
                            height: 132px;
                        }
                    }
                }
                .user_box_left_info_rem {
                    width: 100%;
                    margin-top: 26px;
                    span {
                        font-size: 10px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                    }
                }
                .user_box_left_info_b {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    .user_box_left_info_edit {
                        width: 81px;
                        height: 28px;
                        line-height: 28px;
                        text-align: center;
                        background: linear-gradient(0deg, #085EAF, #2791F4);
                        border-radius: 8px;
                        font-size: 10px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                        cursor: pointer;
                        margin-top: 17px;
                        margin-right: 20px;
                    }
                }
            }
            .user_box_left_num {
                width: 100%;
                height: 84px;
                background: #0D2E64;
                border-radius: 6px;
                margin-top: 9px;
                display: flex;
                align-items: center;
                .user_box_left_num_item {
                    flex: 1;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    position: relative;
                    .user_box_left_num_item_x {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translate(0, -50%);
                        width: 1px;
                        height: 21px;
                        background: #fff;
                    }
                    .a {
                        font-size: 21px;
                        font-family: Source Han Sans CN;
                        font-weight: bold;
                        color: #FFFFFF;
                    }
                    .b {
                        font-size: 10px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                        margin-top: 5px;
                    }
                }
            }
            .user_box_left_art {
                width: 100%;
                height: 350px;
                padding: 20px 20px;
                box-sizing: border-box;
                background: #0D2E64;
                border-radius: 6px;
                margin-top: 9px;
                .user_box_left_art_list {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    .active {
                        span {
                            color: #fff !important;
                        }
                    }
                    .user_box_left_art_list_item {
                        flex: 1;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        span {
                            font-size: 11px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            color: rgba(255, 255, 255, 0.3);
                        }
                        .user_box_left_art_list_item_active {
                            position: absolute;
                            bottom: -5px;
                            left: 50%;
                            width: 31px;
                            height: 2px;
                            background: #48A6FF;
                            border-radius: 1px;
                            transform: translate(-50%, 0);
                        }
                    }
                }
                .user_box_left_art_box::-webkit-scrollbar {
                    width: 5px;
                }
                .user_box_left_art_box::-webkit-scrollbar-thumb {
                    border-radius: 8px;
                    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                    background: #5D6B82;
                }
                .user_box_left_art_box {
                    width: 100%;
                    height: calc(100% - 30px);
                    margin-top: 18px;
                    overflow-y: scroll;
                    .user_box_left_art_box_item {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        cursor: pointer;
                        padding-bottom: 10px;
                        margin-bottom: 10px;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                        &:last-child {
                            margin-bottom: 0 !important;
                            border: none;
                        }
                        .art_title {
                            font-size: 11px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            color: #FFFFFF;
                            margin-bottom: 5px;
                            display: flex;
                        }
                        .art_info::v-deep {
                            font-size: 11px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            color: #779FE0;
                            overflow:hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 2;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            img {
                                width: 100%;
                            }
                        }
                        .art_time {
                            display: flex;
                            align-items: center;
                            margin-top: 5px;
                            span {
                                font-size: 10px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                color: #3661A6;
                                &:first-child {
                                    margin-right: 25px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .user_box_right {
            width: 245px;
            .user_box_right_info {
                width: 100%;
                padding: 20px;
                height: 288px;
                box-sizing: border-box;
                background: #0D2E64;
                border-radius: 6px;
                margin-top: 9px;
                .user_box_right_info_item {
                    width: 100%;
                    height: 55px;
                    padding: 12px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    background: rgba(32, 86, 137, 0.5);
                    border-radius: 6px;
                    margin-bottom: 9px;
                    img {
                        flex-shrink: 0;
                        width: 13px;
                        height: 15px;
                        margin-right: 13px;
                    }
                    .user_box_right_info_item_right {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        span {
                            font-size: 10px;
                            font-family: Source Han Sans CN;
                            font-weight: 500;
                            color: #FFFFFF;
                            &:last-child {
                                margin-top: 7px;
                            }
                        }
                    }
                }
            }
            .user_box_right_integral {
                width: 245px;
                height: 348px;
                padding: 20px;
                background: #0D2E64;
                border-radius: 6px;
                box-sizing: border-box;
                .user_box_right_integral_list::-webkit-scrollbar {
                    width: 5px;
                }
                .user_box_right_integral_list::-webkit-scrollbar-thumb {
                    border-radius: 8px;
                    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                    background: #5D6B82;
                }
                .user_box_right_integral_list {
                    width: 100%;
                    height: calc(100% - 58px - 46px);
                    overflow: auto;
                    margin-top: 23px;
                    .user_box_right_integral_list_item {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 23px;
                        &:first-child {
                            margin-top: 0 !important;
                        }
                        .integral_content {
                            flex: 1;
                            overflow:hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            -o-text-overflow:ellipsis;
                            font-size: 9px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            color: #FFFFFF;
                        }
                        .integral_num {
                            flex-shrink: 0;
                            margin-left: 20px;
                            font-size: 9px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            color: rgba(41, 232, 15, 1);
                        }
                    }
                }
                .user_box_right_integral_footer {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 28px;
                    margin-top: 23px;
                    .user_box_right_integral_footer_btn {
                        width: 112px;
                        height: 28px;
                        line-height: 28px;
                        text-align: center;
                        background: linear-gradient(0deg, #085EAF, #2791F4);
                        border-radius: 6px;
                        font-size: 10px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                        cursor: pointer;
                    }
                }
            }
        }
        .close {
            position: absolute;
            right: -10px;
            top: -10px;
            width: 31px;
            height: 31px;
            cursor: pointer;
        }
    }
// }
.user_box_right_integral_head {
  width: 100%;
  height: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .c {
    font-size: 13px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
  }
  .d {
    font-size: 13px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: rgba(243, 172, 19, 1);
  }
  img {
    width: 100%;
    height: 6px;
    position: absolute;
    top: 13px;
    left: 0;
  }
}
</style>
